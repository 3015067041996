// import "./js/css-preload";
// import "./js/jquery.easing.min";
// import "./js/zoom.min";
// import "./js/hammer";
// import "./js/svg-pan-zoom.min";
// import "@fortawesome/fontawesome-free/js/fontawesome";

// import "@fortawesome/fontawesome-free/js/solid/"
// import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'
